import Vue from 'vue'

const NewsletterService = {

  // Newsletters

  setNewsletter(item) {
    return Vue.prototype.$http.post('/api/services/app/NewsletterRegisters/CreateOrEdit', item)
  },
}

export default NewsletterService
