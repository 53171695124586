export default {
  data() {
    return {
      winW: window.innerWidth,
      winH: window.innerHeight,
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    winSM() {
      return this.winW < 601
    },

    winLG() {
      return this.winW > 1200
    },

    winMD() {
      return this.winW > 600 && this.winW < 1201
    },
  },

  methods: {
    onResize() {
      this.winW = window.innerWidth
      this.winH = window.innerHeight
    },
  },
}
